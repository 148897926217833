@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Poppins" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card {
  border-radius: 1rem;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

input {
  border: 1px solid #e7e7e7;
  background: #fff;
  border-radius: 5px;
  padding: 0.6rem 0.8rem;
  box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
  width: 100%;
  font-size: 14px;
  font-size: var(--font-size);
  color: #444;
}
.button.cta {
  border: 2px solid #264753;
  border: 2pxsolidvar (--dark-blue);
  color: #264753;
  /* color: var(--dark-blue); */
  background: #edf6f9;
  background: var(--sky-blue);
  font-weight: 600;
  font-weight: var(--font-weight);
  font-size: 16px;
}
.button {
  padding: 10px;
  border-radius: 10px;
  background: #eee;
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Collapsible {
  background-color: transparent;
}

.Collapsible__contentInner {
  border-top: 0;
}

p {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
}
p:last-child {
  margin-bottom: 0;
}

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  border: 1px solid white;
  padding: 10px;
  background: rgb(224, 224, 224);
  color: black;
}
.Collapsible__trigger :after {
  font-family: "FontAwesome";
  content: "\f107";
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  transition: transform 300ms;
}

/*.Collapsible__trigger.is-open {
	transform: rotateZ(180deg);
}*/
.Collapsible__trigger:after {
  transform: rotateZ(180deg);
}

.Collapsible__trigger .is-disabled {
  opacity: 0.5;
  background-color: grey;
}

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
}

.CustomTriggerCSS--open {
  background-color: darkslateblue;
}

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #cbb700;
  color: black;
}

.Collapsible__trigger.is-open .lesson-arow {
  transform: rotateZ(90deg);
}

.cource_bg {
  padding-top: 20px;
  background: #f8fcfd 0 0 no-repeat padding-box;
  opacity: 1;
}
.small-title {
  font-weight: 600;
}
.small-text {
  font-size: var(--font-size-sm);
}
.button.cta {
  border: 2px solid #264753;
  border: 2pxsolidvar (--dark-blue);
  color: #264753;
  color: var(--dark-blue);
  background: #edf6f9;
  background: var(--sky-blue);
  font-weight: 600;
  font-weight: var(--font-weight);
  font-size: 16px;
}
.card {
  border-radius: 1rem !important;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}
.input {
  border: 1px solid #e7e7e7;
  background: #fff;
  border-radius: 5px;
  padding: 0.6rem 0.8rem;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  font-size: var(--font-size);
  color: #444;
}

.input::placeholder {
  color: var(--text-light);
}
